<template>
  <div class="export-check content-container">
    <h3 class="title">Проверить выгрузку на сайт:</h3>
    <img src="../../assets/export/check.png" alt="" class="export-check" />
  </div>
</template>

<script>
export default {
  name: "ExportCheck",
}
</script>

<style lang="scss" scoped>
.export-check {
  max-width: 100%;

  .title {
    margin-bottom: 10px;
  }
}
</style>
