<template>
  <div class="export-page">
    <ExportsTable />
    <ExportUnloadFile />
    <ExportPathFile />
    <ExportCheck />
  </div>
</template>

<script>
import ExportsTable from "@/components/export/ExportsTable"
import ExportPathFile from "@/components/export/ExportPathFile"
import ExportUnloadFile from "@/components/export/ExportUnloadFile"
import ExportCheck from "@/components/export/ExportCheck"

export default {
  name: "Export",
  components: {
    ExportUnloadFile,
    ExportPathFile,
    ExportsTable,
    ExportCheck,
  },
}
</script>

<style lang="scss" scoped></style>
