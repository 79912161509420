<template>
  <div class="exports-table">
    <a-button class="hide-button" @click="toggleVisibility">
      {{
        isHiddenTable
          ? "Показать предыдущие выгрузки"
          : "Скрыть предыдущие выгрузки"
      }}
    </a-button>

    <div v-if="!isHiddenTable" class="content-container">
      <a-table
        bordered
        :data-source="data"
        :columns="columns"
        class="exports-table"
      ></a-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"

export default {
  name: "ExportsTable",
  mounted() {
    this.fetchData()
  },
  computed: mapGetters({
    columns: "exports/columns",
    data: "exports/data",
    isHiddenTable: "exports/isHiddenTable",
  }),
  methods: {
    ...mapActions({
      fetchData: "exports/fetchData",
    }),
    ...mapMutations({
      toggleVisibility: "exports/TOGGLE_TABLE_VISIBILITY",
    }),
  },
}
</script>

<style lang="scss" scoped>
.exports-table {
  .hide-button {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
