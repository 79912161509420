<template>
  <div class="export-unload-file">
    <h3>Установить путь выгрузки:</h3>
    <a-form class="upload-form">
      <BrandSelect :on-change="onChange" :current-brand="currentBrand" />
      <a-upload name="file">
        <a-button class="file-upload">
          <a-icon type="upload" />
          Файл
        </a-button>
      </a-upload>
      <a-button>Обновить</a-button>
    </a-form>

    <div class="actual-paths content-container">
      <h4>Актуальные пути:</h4>
      <p>
        <a
          href="https://www.jlrconnect.ru/PartCheck/Land%20Rover?_ga=2.98028831.1428849701.1607732261-1351824714.1597138774"
          target="_blank"
        >
          https://www.jlrconnect.ru/PartCheck/Land%20Rover?_ga=2.98028831.1428849701.1607732261-1351824714.1597138774
        </a>
      </p>
      <p>
        <a
          href="https://www.jlrconnect.ru/PartCheck/Jaguar?_ga=2.123878733.653960556.1607875309-154005044.1596039966"
          target="_blank"
        >
          https://www.jlrconnect.ru/PartCheck/Jaguar?_ga=2.123878733.653960556.1607875309-154005044.1596039966
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import BrandSelect from "@/components/brand-select/BrandSelect"

import { mapMutations, mapGetters } from "vuex"

export default {
  name: "ExportPathFile",
  components: {
    BrandSelect,
  },
  computed: mapGetters({
    currentBrand: "csDirectories/currentBrand",
  }),
  methods: {
    ...mapMutations({
      chooseBrand: "csDirectories/CHOOSE_BRAND",
    }),
    onChange(brandId) {
      this.chooseBrand(brandId)
    },
  },
}
</script>

<style lang="scss" scoped>
.export-unload-file {
  margin: 20px 0;

  .upload-form {
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 10px;
    padding: 10px;

    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    .file-upload {
      width: 200px;
    }
  }

  .actual-paths {
    margin-bottom: 15px;
  }
}
</style>
