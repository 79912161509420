<template>
  <div class="export-unload-file">
    <h3>Сформировать файл выгрузки:</h3>
    <a-form class="upload-form">
      <BrandSelect :on-change="onChange" :current-brand="currentBrand" />
      <a-upload name="file">
        <a-button class="file-upload">
          <a-icon type="upload" />
          Файл
        </a-button>
      </a-upload>
      <a-button>Выгрузить</a-button>
    </a-form>
  </div>
</template>

<script>
import BrandSelect from "@/components/brand-select/BrandSelect"

import { mapMutations, mapGetters } from "vuex"

export default {
  name: "ExportUnloadFile",
  components: {
    BrandSelect,
  },
  computed: mapGetters({
    currentBrand: "csDirectories/currentBrand",
  }),
  methods: {
    ...mapMutations({
      chooseBrand: "csDirectories/CHOOSE_BRAND",
    }),
    onChange(brandId) {
      this.chooseBrand(brandId)
    },
  },
}
</script>

<style lang="scss" scoped>
.export-unload-file {
  margin: 20px 0;

  .upload-form {
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 10px;
    padding: 10px;

    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    .file-upload {
      width: 200px;
    }
  }
}
</style>
